<template>
    <router-view />
</template>

<style lang="scss">
body{
    margin: 0px;
}
*{
    box-sizing: border-box;
}

ul{
    margin: 0px;
    padding: 0px;
}
li{
    list-style: none;
}

.el-pagination .btn-next .el-icon, 
.el-pagination .btn-prev .el-icon{
    display: inline-block !important;
}
.fenye{
    margin: 15px 0px 0px;
    text-align: right;
}
</style>
